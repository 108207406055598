<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      max-height="800px"
      scrollable
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-card background-color="#f2f2f2" v-else>
        <v-card-title
          class="pb-0"
          style="background-color: #7253cf; color: white"
        >
          <div
            class="ml-7 mb-3 dialogTitle-text text-center"
            style="width: 85%"
          >
            <div class="text-center">Select Audience</div>
            <div class="text-center" style="font-size: 35px; color: #ffd54f">
              {{ audienceCount }}
            </div>
            <div class="text-center" style="font-size: 14px; font-family: Lato">
              Eligible audience
            </div>
          </div>
          <v-icon
            text
            size="32"
            color="white"
            @click="toggleAudienceModal({ show: false })"
            style="cursor: pointer; position: absolute; right: 10px; top: 10px"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-10 px-4">
          <v-row no-gutters v-if="layout === 'AdminDashboardLayout'">
            <v-col cols="6" class="px-2">
              <v-autocomplete
                label="Select District"
                outlined
                dense
                v-model="district"
                :items="districtList"
                class="formFields"
                item-value="id"
                item-text="district_name"
                color="#7253CF"
                :disabled="formLoading"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" class="px-2">
              <v-autocomplete
                label="Select School"
                outlined
                dense
                v-model="school"
                :items="schoolList"
                class="formFields"
                item-value="id"
                item-text="school_name"
                color="#7253CF"
                :disabled="formLoading"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" class="px-2">
              <v-autocomplete
                label="Select Campaign"
                outlined
                dense
                v-model="campaign"
                :items="campaignList"
                class="formFields"
                item-text="campaign_id"
                item-value="id"
                color="#7253CF"
                :disabled="formLoading"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="px-3 pb-0 mt-2">
              <div style="font-size: 18px">Send to</div>
            </v-col>
            <v-col cols="12" class="px-3 pt-0">
              <!-- <v-checkbox
                  color="#7253CF"
                  class="formFields"
                  v-model="item.key"
                  :label="item.text"
                  @change="storeAudience(item.key, item.value)"
                ></v-checkbox> -->
              <!-- {{ selectedAudience }} -->
              <v-radio-group v-model="selectedAudience" row>
                <v-radio
                  v-for="(item, i) in audienceData"
                  :key="i"
                  :label="item.text"
                  :value="item.value"
                  class="py-2"
                  color="#7253CF"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-8 px-6">
          <v-btn
            dark
            height="45"
            color="#2c1963"
            class="text-capitalize px-7"
            style="border-radius: 10px"
            :loading="formLoading"
            @click="submitForm"
          >
            <span>Proceed to compose template</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Axios from "@/api/BaseAxios";
import { ROUTER_URL } from "@/constants/urls";
import {
  API_ADMIN_GET_DISTRICT_LIST,
  API_ADMIN_GET_SCHOOL_LIST,
  API_ADMIN_CAMPAIGN_MANAGEMENT_LIST,
  API_BULK_EMAIL_AUDIENCE_COUNT,
} from "@/constants/APIUrls";
export default {
  name: "BulkEmailAudienceModal",
  data() {
    return {
      loading: false,
      formLoading: false,
      district: null,
      districtList: [],
      school: null,
      schoolList: [],
      campaign: null,
      campaignList: [],
      audienceList: [],
      audienceData: [
        { key: false, value: "Parent", text: "Parents" },
        { key: false, value: "teacher", text: "Teachers" },
        { key: false, value: "school_team", text: "School Team" },
        { key: false, value: "district_team", text: "District Team" },
        { key: false, value: "Donor", text: "Donors" },
      ],
      audienceEditData: [],
      selectedAudience: "",
      audienceCount: 0,
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "emailSettings/getShow",
      getEmailDetail: "emailSettings/getComposeEmailDetail",
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAudienceModal({ show: value });
      },
    },
    layout() {
      return this.$route.meta.layout;
    },
    type() {
      return this.$store.state.emailSettings.addEditViewModal.type;
    },
  },
  watch: {
    show(value) {
      if (value) {
        setTimeout(() => {
          this.openCallModal();
        }, 100);
      } else {
        this.closeCallModal();
      }
    },
    district: function () {
      this.getCounts();
      if (this.district) {
        this.getSchool();
      }
    },
    school: function () {
      this.getCounts();
      if (this.school) {
        this.getCampaign();
      }
    },
    campaign() {
      this.getCounts();
    },
    selectedAudience() {
      this.getCounts();
    },
  },
  methods: {
    ...mapActions({
      toggleAudienceModal: "emailSettings/toggleModal",
      toggleEmailDetail: "emailSettings/toggleEmailDetail",
    }),
    openCallModal() {
      console.log(this.selectedCampaign, "campaign details");
      this.getCounts();
      if (this.type === "edit") {
        this.getDetail();
        this.loading = true;
      } else {
        this.getDistrict();
      }
    },
    closeCallModal() {
      this.district = null;
      this.districtList = [];
      this.school = null;
      this.schoolList = [];
      this.campaign = null;
      this.campaignList = [];
      this.audienceList = [];
      this.audienceData = [
        { key: false, value: "Parent", text: "Parents" },
        { key: false, value: "teacher", text: "Teachers" },
        { key: false, value: "school_team", text: "School Team" },
        { key: false, value: "district_team", text: "District Team" },
        { key: false, value: "Donor", text: "Donors" },
      ];
      this.audienceCount = 0;
      this.selectedAudience = "";
    },
    getDistrict() {
      this.loading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.districtList = res.data.district_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        this.getSchool();
      };
      let formData = {};
      Axios.request_GET(
        API_ADMIN_GET_DISTRICT_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getSchool() {
      const successHandler = (res) => {
        console.log(res.data);
        this.schoolList = res.data.school_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = () => {
        this.getCampaign();
      };
      let formData = {};
      if (this.district) {
        formData["district_id"] = this.district;
      }
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getCampaign() {
      const successHandler = (res) => {
        console.log(res.data);
        this.campaignList = res.data.campaign_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };

      let formData = {};
      if (this.district) {
        formData["district_id"] = this.district;
      }
      if (this.school) {
        formData["school_id"] = this.school;
      }
      Axios.request_GET(
        API_ADMIN_CAMPAIGN_MANAGEMENT_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getDetail() {
      console.log(this.getEmailDetail);
      this.selectedAudience = this.getEmailDetail.audience.value;
      this.campaign = this.getEmailDetail.campaign.id;
      this.school = this.getEmailDetail.school.id;
      this.district = this.getEmailDetail.district.id;
      this.getDistrict();
    },
    submitForm() {
      let districtDetail = {};
      let schoolDetail = {};
      let campaignDetail = {};
      let audienceDetail = {};

      //panel wise
      if (this.layout === "AdminDashboardLayout") {
        if (this.district) {
          let index = this.districtList.findIndex(
            (item) => item.id === this.district
          );
          districtDetail = { ...this.districtList[index] };
        }
        if (this.school) {
          let index = this.schoolList.findIndex(
            (item) => item.id === this.school
          );
          schoolDetail = { ...this.schoolList[index] };
        }
        if (this.campaign) {
          let index = this.campaignList.findIndex(
            (item) => item.id === this.campaign
          );
          campaignDetail = { ...this.campaignList[index] };
        }
      } else {
        console.log("campaign panel");
        campaignDetail = { ...this.selectedCampaign.campaignData };
        if (this.$route.matched[0].path.substring(1) === "district") {
          districtDetail = {
            district_name: this.selectedCampaign.organizationData.name,
            id: this.selectedCampaign.organizationData.id,
          };
          console.log(districtDetail);
        } else {
          schoolDetail = {
            school_name: this.selectedCampaign.organizationData.name,
            id: this.selectedCampaign.organizationData.id,
          };
          console.log(schoolDetail);
        }
      }
      //panel wise

      if (this.selectedAudience) {
        let index = this.audienceData.findIndex(
          (item) => item.value === this.selectedAudience
        );
        audienceDetail = { ...this.audienceData[index] };
      }
      let emailDetail = {
        district: districtDetail,
        school: schoolDetail,
        campaign: campaignDetail,
        audience: audienceDetail,
      };
      this.toggleEmailDetail(emailDetail);
      this.toggleAudienceModal({ show: false });
      if (this.layout === "AdminDashboardLayout") {
        if (this.type === "add") {
          this.$router.push({
            name: ROUTER_URL.adminPanel.children.bulkEmailForm.name,
            query: { type: "add" },
          });
        } else {
          this.$emit("reload");
        }
      } else {
        this.$route.query["type"] = "add";
        this.$emit("reload");
        this.$root.$refs.bulkEmail.changeTab(false);
      }
    },

    getCounts() {
      const successHandler = (res) => {
        this.audienceCount = res.data.total_audience;
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      const finallyHandler = () => {};
      let formData = {};
      if (this.layout === "AdminDashboardLayout") {
        if (this.campaign) {
          formData["campaign_id"] = this.campaign;
        }
        if (this.district) {
          formData["district_id"] = this.district;
        }
        if (this.school) {
          formData["school_id"] = this.school;
        }
      } else {
        const PANEL = this.$route.matched[0].path.substring(1);
        formData["campaign_id"] = this.selectedCampaign.campaignData.id;
        formData[PANEL + "_id"] = this.selectedCampaign.organizationData.id;
      }
      if (this.selectedAudience) {
        formData["send_to"] = this.selectedAudience;
      }
      Axios.request_GET(
        API_BULK_EMAIL_AUDIENCE_COUNT,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
  },
  mounted() {
    const PANEL = this.$route.matched[0].path.substring(1);
    if (PANEL === "school") {
      let index = this.audienceData.findIndex(
        (item) => item.value === "district_team"
      );
      this.audienceData.splice(index, 1);
    }
  },
};
</script>
<style scoped>
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #ffffff;
}
.v-btn >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  /* or 29px */
  color: #ffffff;
  letter-spacing: 0px;
  font-size: 17px;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height */

  color: #757575;
}
</style>
